// other imports
import 'bootstrap';
import Honeybadger from '@honeybadger-io/js';
import { Notyf } from "notyf";
import "chartkick/chart.js";

const notyf = new Notyf({
  duration: 1000,
  position: {
    x: 'right',
    y: 'top',
  },
  dismissible: true,
});
window.notyf = notyf;

// Grab Action Names
const {
  controller,
  action,
  notice,
  alert,
} = document.body.dataset;

if (notice) {
  notyf.success(notice);
} else if (alert) {
  notyf.error(alert);
}

if (controller === 'application' && action === 'home') {
  // we don't need a separate file, so let's just create the call and go ahead.
  const azureLogin = document.getElementById('azure-login');
  azureLogin.submit();
  console.log('Redirecting you to Azure...');
}
